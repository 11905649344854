import roadmapHeader from "../assets/headers/roadmap.gif";
import phases from "../assets/phases.png";

import phase1 from "../assets/phase1.png";
import phase2 from "../assets/phase2.png";
import phase3 from "../assets/phase3.png";
import phase4 from "../assets/phase4.png";

import top1 from "../assets/top1.png";
import top2 from "../assets/top2.png";
import top3 from "../assets/top3.png";
import top4 from "../assets/top4.png";

import bottom1 from "../assets/bottom1.png";
import bottom2 from "../assets/bottom2.png";
import bottom3 from "../assets/bottom3.png";
import bottom4 from "../assets/bottom4.png";
import ScreenPoints from "./ScreenPoints";

const Phase = ({ top, bottom, children }) => {
  return (
    <div className="relative flex flex-col">
      <img
        src={top}
        className="relative z-20"
        // style={{ width: "100%", height: "100%" }}
      />
      <img
        src={bottom}
        className="hidden sm:block absolute z-10 mt-10"
        style={{ width: "100%", height: "100%" }}
      />
      <div
        className="hidden sm:block mx-3 text-md leading-7  mt-5
      md:text-lg md:mx-5 
      lg:text-lg  
      xl:text-xl xl:mx-8 xl:leading-8"
        style={{ minHeight: "10vh" }}
      >
        {children}
      </div>

      <div
        className="sm:hidden text-xs text-md leading-7 w-full rounded-b-md bg-white border-4 border-solid border-black "
        style={{ minHeight: "10vh" }}
      >
        {children}
      </div>
    </div>
  );
};

const Roadmap = (props) => {
  return (
    <div
      className="flex flex-col-reverse lg:flex-row justify-center items-center pb-10"
      style={{ width: "100%" }}
      id="roadmap"
    >
      {/* <ScreenPoints /> */}
      <div className="lg:w-1/2">
        <div className="flex flex-row space-x-5">
          <div className="flex flex-col space-y-10">
            <Phase top={top1} bottom={bottom1}>
              <ul
                className="relative z-20   list-disc list-inside"
                style={{ fontFamily: "pyramid" }}
              >
                <li>Build Community</li>
                <li>ETH / WL giveaways through twitter</li>
                <li>Reveal Partnerships / Collaborations</li>
                <li>Launch Discord</li>
                <li>Announce Utility</li>
              </ul>
            </Phase>

            <Phase top={top3} bottom={bottom3}>
              <ul
                className="relative z-20  list-disc list-inside"
                style={{ fontFamily: "pyramid" }}
              >
                <li>Exclusive Merch Drops / Collabs</li>
                <li>IRL music, skate, art event </li>
              </ul>
            </Phase>
          </div>

          <div className="flex flex-col mt-10 space-y-10">
            <Phase top={top2} bottom={bottom2}>
              <ul
                className="relative z-20  list-disc list-inside"
                style={{ fontFamily: "pyramid" }}
              >
                <li>Launch Pyramid Punx collection of 8,888 </li>
                <li>Upon sellout donate $50,000 to the Jed Foundation</li>
                <li>
                  Incorporate certified mental health experts into our discord{" "}
                </li>
              </ul>
            </Phase>

            <Phase top={top4} bottom={bottom4}>
              <p
                className="relative z-20 leading-10 "
                style={{ fontFamily: "pyramid" }}
              >
                TBA
              </p>
            </Phase>
          </div>
        </div>
      </div>
      <div className=" w-5/6 lg:w-2/5 lg:flex justify-end">
        <img src={roadmapHeader} style={{ maxHeight: "75vh" }} />
      </div>
    </div>
  );
};

export default Roadmap;
