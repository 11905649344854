import teamHeader from "../assets/headers/team.gif";
import teambubble from "../assets/teambubble.png";

import team1 from "../assets/team1.png";
import team2 from "../assets/team2.png";

import newTeam1 from "../assets/GhastlyTeam.jpg"
import newTeam2 from "../assets/newTeam1.jpg"
import team3 from "../assets/team3.jpg"
import team4 from "../assets/team4.jpg"
import team5 from '../assets/team5.png'

import twitter from "../assets/twitterBlack.png";
import instagram from "../assets/instagram.png"
import newBubble from "../assets/newBubble.PNG";
import ScreenPoints from "./ScreenPoints";

const TeamRow = ({ img, name, position, twitterName, twitterLink, instagramName, instagramLink }) => {
  return (
    <div className="flex flex-col  sm:flex-row justify-between items-center">
      <div className="sm:w-1/2 lg:w-2/5 ease">
        <img src={img} className="rounded-lg border-solid border-8 border-black " style={{ width: "100%" }} />
      </div>
      <div className="w-full sm:w-1/2 relative">
        <img
          src={newBubble}
          className="absolute z-10"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          className="relative text-center z-20 ease
          p-5 sm:text-lg sm:py-10
          md:text-xl md:leading-8
          lg:text-3xl lg:leading-10 lg:px-8 lg:py-12 lg:h-max
          xl:text-4xl xl:leading-loose xl:py-16
          "
          style={{ fontFamily: "pyramid" }}
        >
          <p style={{}}>{name}</p>
          <p>{position}</p>

          <div>
            {twitterName ?  <a
              className="flex flex-row justify-center items-center space-x-2 "
              target="_blank"
              href={twitterLink}
            >
              <p>{twitterName}</p>
              <img src={twitter} className="h-10" />
            </a> : <a
              className="flex flex-row justify-center items-center space-x-2 "
              target="_blank"
              href={instagramLink}
            >
              <p>{instagramName}</p>
              <img src={instagram} className="h-10" />
            </a>}
           
          </div>
        </div>
      </div>
    </div>
  );
};

const Team = (props) => {
  return (
    <div id="team" className="pb-28 lg:mx-20">
      <div className="flex flex-row justify-center ">
       
        <div className=" w-5/6 lg:w-2/5">
        <img src={teamHeader} style={{ maxHeight: "75vh" }} />
      </div>
      </div>
      <div className="flex flex-col space-y-10">
        <TeamRow
          img={newTeam2}
          name={"Lauren Hawley"}
          position={"Founder & Artistic Director"}
          twitterLink={"https://twitter.com/Hawleykinz"}
          twitterName={"@hawleykinz"}
        />
        <TeamRow
          img={newTeam1}
          name={"David Crow"}
          position={"Co-Founder"}
          twitterLink={"https://twitter.com/Ghastly"}
          twitterName={"@ghastly"}
        />
           <TeamRow
          img={team3}
          name={"Naccavelli "}
          position={"Head community lead "}
          twitterLink={"https://twitter.com/Naccavelli"}
          twitterName={"@Naccavelli"}
        />
           <TeamRow
          img={team4}
          name={"The Drop NFT"}
          position={"Project Advisor"}
          twitterLink={"https://twitter.com/thedropnft"}
          twitterName={"@thedropnft"}
        />
               {/* <TeamRow
          img={team5}
          name={"Alec Celestin"}
          position={"Growth & Community Advisor"}
          instagramLink={"https://www.instagram.com/fhotos/"}
          instagramName={"@fhotos"}
        /> */}
      </div>
    </div>
  );
};

export default Team;
