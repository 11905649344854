import React, {useState, useEffect} from "react";
import header from "../assets/header.gif"
import icon from "../assets/icon.png"
const Header = (props) => {

  const [aboutElement, setAboutElement] = useState(null);
  const [roadmapElement, setRoadmapElement] = useState(null);
  const [teamElement, setTeamElement] = useState(null);


  useEffect(() => {
    setAboutElement(document.getElementById("about"))
    setRoadmapElement(document.getElementById("roadmap"))
    setTeamElement(document.getElementById("team"))
  }, [])

  // const history = useHistory();

  // useEffect(() => {
  //   if (section) {
  //     switch (section) {
  //       case "#about":
  //         aboutElement && aboutElement.scrollIntoView({ behavior: "smooth" })
  //         break
  //       case "#roadmap":
  //         roadmapElement && roadmapElement.scrollIntoView({ behavior: "smooth" })
  //         break
  //       case "#team":
  //         teamElement && teamElement.scrollIntoView({ behavior: "smooth" })
  //         break
  //     }
  //   }
  // }, [aboutElement, roadmapElement, teamElement])

  // const goToSection = (location) => {
  //   // let currentLoc = window.location.pathname.split("/")[1]
  //   history.push({ pathname: `/#${location}` });
  // }





  return (
    <div>
      <img src={header} />
      <div
        className=" border-y-8 border-solid border-black bg-brandYellow text-white flex flex-row space-x-5 lg:space-x-10 justify-between px-3 md:px-20 items-center text-xl lg:text-5xl py-3 font-bold h-24"
        style={{ maxWidth: "100vw", fontFamily: "pyramid" }}
      >
        <button className="outlined tracking-widest 
        hover:text-2xl
        hover:lg:text-6xl ease
        " onClick={() => aboutElement.scrollIntoView({ behavior: "smooth" })}>ABOUT </button>
        <img src={icon} className="hidden sm:block h-14 spin" />
        <button
        onClick={() => roadmapElement.scrollIntoView({ behavior: "smooth" })}
        className="outlined tracking-widest
        hover:text-2xl
        hover:lg:text-6xl ease
        ">ROADMAP</button>
        <img src={icon} className="hidden sm:block h-14 spin" />
        <button className="outlined tracking-widest
         
        
        hover:text-2xl
        hover:lg:text-6xl ease"
        onClick={() => teamElement.scrollIntoView({ behavior: "smooth" })}
        >OUR TEAM</button>
      </div>
    </div>
  );
};

export default Header;
