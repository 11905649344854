import About from "../components/About";
import Header from "../components/Header";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team";
import Welcome from "../components/Welcome";

const Home = (props) => {
  return (
    <div className="bg" style={{ minHeight: "100vh" }}>
      <Header />
      <div className="mx-10 space-y-10">
        <Welcome />
        <About />
        <Roadmap />
        <Team />
      </div>
      <div className="bg-black z-20" style={{width: "100%"}}>
          <p className="text-white text-center tracking-wide font-extrabold py-3 ">Made with{"  "}  ❤️{"  "}  by Pyramid Punx</p>
      </div>
    </div>
  );
};
export default Home;
