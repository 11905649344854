import guy from "../assets/mainCharacter.png";
import twitter from "../assets/twit.png";
import discord from "../assets/discord.png";
import ScreenPoints from "./ScreenPoints";

const Welcome = (props) => {
  return (
    <div>
      <div className="flex justify-center mt-10 lg:hidden">
        
        <a
          className=""
          target="_blank"
          href="https://twitter.com/PyramidPunx"
        >
          <img src={twitter} className=" md:h-28  md:hover:h-36 ease" />
        </a>
        <a className="mt-5">
          <img src={discord} className=" md:h-28 md:hover:h-36 ease" />
        </a>
      </div>

      <div className="flex flex-row justify-center lg:mt-10">
        <div className="hidden lg:block mt-20 ease">
          <a
            className=""
            target="_blank"
            href="https://twitter.com/PyramidPunx"
          >
            <img src={twitter} className="h-36 hover:h-40 ease" />
          </a>
        </div>
        
          <img src={guy} className="lg:w-1/2 xl:w-2/5 ease" />
        
        <div className="hidden lg:block mt-44 ease">
          <a className=""
           target="_blank"
           href="https://discord.com/invite/pyramidpunx"
          >
            <img src={discord} className="h-36 hover:h-40 ease" />
          </a>
        </div>
      </div>
      {/* <ScreenPoints /> */}
    </div>
  );
};

export default Welcome;
