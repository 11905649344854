import aboutHeader from "../assets/headers/about.gif";
import bubble from "../assets/bubble.png";
import newbubble from "../assets/newBubble.PNG";
import ScreenPoints from "./ScreenPoints";
import ball from "../assets/ball.PNG";

const About = (props) => {
  return (
    <div
      className="flex flex-col lg:flex-row items-center justify-center"
      style={{ width: "100%" }}
      id="about"
    >
      <div className="mb-5 sm:mb-0 w-5/6 lg:w-2/5 flex justify-center">
        <img src={aboutHeader} style={{ maxHeight: "75vh" }} />
      </div>
      <div className="lg:w-1/2 relative">
        <div
          className="hidden sm:flex absolute justify-center"
          style={{ width: "100%" }}
        >
          <img
            src={ball}
            className="z-30 relative -top-2 sm:-top-5 lg:-top-6"
            style={{ maxWidth: "20vw" }}
          />
        </div>

        <img
          src={newbubble}
          className="hidden sm:block  absolute z-10"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          className="hidden sm:block relative z-20 leading-10 mx-10 md:mx-24 my-20 "
          style={{ fontFamily: "pyramid" }}
        >
          <p className="text-lg leading-10">
            Pyramid Punx is a female founded and led project that aims to bring
            together the music, skateboarding, and art worlds in a never been
            done before IRL experience.
          </p>
          <p className="text-lg leading-10">
            8,888 auto generated Punx including 100 honorables serve as
            inclusive access to our events, merch, and future endeavors.
          </p>
          <p className="text-lg leading-10">
            Our vision is to create a community built on our 4 pillars of
            strength: authenticity, reliability, connection, and support.
          </p>
        </div>

        <div
          className="flex sm:hidden absolute justify-center"
          style={{ width: "100%" }}
        >
          <img
            src={ball}
            className="z-30 relative -top-5"
            style={{ maxWidth: "10vw" }}
          />
        </div>
        <div
          className="sm:hidden relative z-20 leading-10 px-10 pb-5 pt-5 rounded-md bg-white border-4 border-solid border-black "
          style={{ fontFamily: "pyramid" }}
        >
          <p className="text-lg leading-10">
            Pyramid Punx is a female founded and led project that aims to bring
            together the music, skateboarding, and art worlds in a never been
            done before IRL experience.
          </p>
          <p className="text-lg leading-10">
            8,888 auto generated Punx including 100 honorables serve as
            inclusive access to our events, merch, and future endeavors.
          </p>
          <p className="text-lg leading-10">
            Our vision is to create a community built on our 4 pillars of
            strength: authenticity, reliability, connection, and support.
          </p>
        </div>
      </div>
      {/* <ScreenPoints /> */}
    </div>
  );
};

export default About;
